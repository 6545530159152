@import "@/styles/_variables.scss";




































































































































































.tooltip {
  color: #e69393;
}
.iconic-player {
  width: "100%";
  height: 365px;
  border: 1px solid var(--border-color);
}
